import { useHistory } from 'react-router-dom';

import { MobileMenuHeader, SubHeader, DesktopMenuHeader } from '../../components/MenuHeader';

import { useAppSettings } from '../../hooks/useAppSettings';

export const TermsPage = () => {
  const navigate = useHistory();
  const { data: settings } = useAppSettings();
  return (
    <div className="bg-neutral-700 bg-opacity-5">
      <DesktopMenuHeader />
      <MobileMenuHeader prompt onBack={() => navigate.goBack()} />
      <SubHeader title="Terms & Conditions" className="p-4" />
      <div
        className="px-4 py-4"
        dangerouslySetInnerHTML={{
          __html: settings?.TERMS_OF_SERVICE ?? '',
        }}
      ></div>
      <div className="pt-10" />
    </div>
  );
};

export const PrivacyPolicyPage = () => {
  const navigate = useHistory();
  const { data: settings } = useAppSettings();
  return (
    <div className="bg-neutral-700 bg-opacity-5">
      <DesktopMenuHeader />
      <MobileMenuHeader prompt onBack={() => navigate.goBack()} />
      <SubHeader title="Privacy Policy" className="p-4" />
      <div
        className="px-4 py-4"
        dangerouslySetInnerHTML={{
          __html: settings?.PRIVACY_POLICY ?? '',
        }}
      ></div>
      <div className="pt-10" />
    </div>
  );
};
